import React, { useEffect, useState } from "react";
import Button from "./Button"

const Table = ({ type }) => {
    const [apiData, setApiData] = useState([])

    useEffect(() => {
        if (type) {
            const symbols = symbolHandler(type)
            const urlHandler = apiUrlHandler(type)
            let promises = symbols.map((symbol) => {
                return requestHandler(urlHandler(symbol))
            })

            Promise.all(promises)
                .then(async (responses) => {
                    let result = [];
                    for (const response of responses) {
                        try {
                            result.push(await response.json());
                        } catch (error) {
                            console.error('JSON Parse Error:', error);
                        }
                    }
                    setApiData(result);
                })
                .catch((error) => {
                    console.error('Data Fetch Error:', error);
                });
        }
    }, [])

    useEffect(() => {
        console.log(apiData)
    }, [apiData])

    return (
        <div className="container-full mx-auto py-20">
            <div className="container-max mx-auto">
                <div className="py-10">
                    <table className="table-auto mx-auto max-w-xl w-full font-extralight">
                        <thead className="text-base font-light text-[#373737]">
                            <tr className="leading-7">
                                <td className="text-left">Symbols</td>
                                {type == 'indice' ?
                                    <td className="md:px-6 px-2 text-right">Price</td>
                                    :
                                    <>
                                        <td className="md:px-6 px-2 text-right">Bid</td>
                                        <td className="md:px-6 px-2 text-right">Ask</td>
                                    </>
                                }
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {apiData.length > 0 ?
                                apiData.map((data, index) => {
                                    return (
                                        <tr key={data.symbol} className={`leading-7 ${index !== apiData.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                            <td className="font-semibold text-lg">{type == 'forex' ? `${data.symbol.slice(0, 3)}/${data.symbol.slice(3, 6)}` : type == 'crypto' ? data.symbol.replace('USD', '/USD') : data.symbol}</td>
                                            {type == 'indice' ?
                                                <td className="font-light md:px-6 px-2 text-right">{data.price}</td>
                                                :
                                                <>
                                                    <td className="md:px-6 px-2 text-right font-light">{data.bid}</td>
                                                    <td className="md:px-6 px-2 text-right font-light">{data.ask}</td>
                                                </>
                                            }
                                            <td className="px-2"><Button content="BUY" color="bg-monstrade-button-green" py="py-0.33" px="px-2.5" /></td>
                                            <td><Button content="SELL" color="bg-monstrade-button-red" py="py-0.33" px="px-3.5" /></td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan="4" className="text-center">No data available</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

const apiUrlHandler = (type) => {
    if (type === 'forex') {
        return (symbol) => {
            return `/finage/last/forex/${symbol}?apikey=API_KEY${process.env.REACT_APP_FINAGE_API_KEY}`
        }
    };

    if (type === 'crypto') {
        return (symbol) => {
            return `/finage/last/quote/crypto/${symbol}?apikey=API_KEY${process.env.REACT_APP_FINAGE_API_KEY}`
        }
    };

    if (type === 'indice') {
        return (symbol) => {
            return `/finage/last/index/${symbol}?apikey=API_KEY${process.env.REACT_APP_FINAGE_API_KEY}`
        }
    };

    if (type === 'stock') {
        return (symbol) => {
            return `/finage/last/stock/${symbol}?apikey=API_KEY${process.env.REACT_APP_FINAGE_API_KEY}`
        }
    };

    return null
}

const symbolHandler = (type) => {
    if (type === 'forex') return ['EURGBP', 'USDGBP', 'EURUSD', 'USDJPY']
    if (type === 'crypto') return ['BTCUSD', 'ETHUSD', 'SOLUSD', 'LINKUSD']
    if (type === 'indice') return ['SPX', 'FTSE', 'GDAXI', 'NDX']
    if (type === 'stock') return ['AAPL', 'TSLA', 'AMZN', 'GOOGL']
    return []
}

const requestHandler = (url) => {
    return fetch(url,
        {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Origin": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH"
            }
        })
}

export default Table;