import React from 'react'
import Banner from '../../assets/images/banners/pamm-mam-banner.png'
import CampaignBanner from '../../components/Banners/CampaignBanner'

const PammMam = () => {
  return (
    <>
    
      <CampaignBanner
        header='| MONSTRADE PRIVILEGES'
        header2='PAMM & MAM'
        desc='Forex trading involves high risks and may result in the loss of your entire investment. Avoid trading without adequate knowledge.'
        Banner={Banner}
      />
      <div className='bg-[#F3F5F6] p-16 text-[#787878]'>
        <div className='max-w-[1440px] mx-auto bg-white p-16'>
          <p className='text-3xl font-bold pb-4'>PAMM TRADING</p>
          <p>
            PAMM is an investment module where investors' funds are combined into a single account managed by their preferred trader. This system provides for a proportional distribution of profits and losses based on the investment of each investor involved.
            <br /><br />
            In order to be included in the PAMM system, you must first contact Monstrade consultants and submit your request.
            <br /><br />
            A trader is selected among the successful traders and then the balance is transferred to the selected trader's account.
            <br /><br />
            Monstrade does not provide any guidance regarding trader selection. This choice is entirely at the investor's discretion.
            <br /><br />
            The investor must approve the PAMM system integration in his/her account with the USER PERMISSION AGREEMENT DOCUMENT.
            <br /><br />
            After the investor submits the document to the relevant units of Monstrade, it is included in the system within 2 business days at the latest and the investor is informed. This process is done only at the end of the day.
            <br /><br />
            Investors share the amount they deposited into the account of the trader they prefer according to their rates. For example, if an investor deposits 10,000 USD and the trader trades with 100,000 USD, the investor's account size is 10% of the total fund.
            <br /><br />
            Profits and losses are reflected in the investor's account and the authority to make transactions belongs to the trader preferred by the investor.
            <br /><br />
            In the PAMM system, there is no possibility to change the TP/SL point or close the transaction whenever they want. All transaction management takes place through a single account of the investor's preferred trader.
          </p>

          <p className='text-3xl font-bold py-4'>MAM TRADING</p>
          <p>
            The MAM system is a system that allows investors to copy trades to their own investment accounts, providing an opportunity to make profits.
            <br /><br />
            To participate in the MAM system, you need to first contact MonsTrade consultants and submit your request.
            <br /><br />
            After selecting a successful trader from among the traders, you need to have a sufficient balance in your account.
            <br /><br />
            MonsTrade does not provide any guidance on the selection of traders. This selection is entirely at the discretion of the investor.
            <br /><br />
            The investor must approve the USER PERMISSION AGREEMENT DOCUMENT for MAM system integration in their account.
            <br /><br />
            After the investor submits the document to the relevant departments of Monstrade, they will be included in the system within a maximum of 2 business days, and the investor will be informed. This process is only carried out at the end of each day.
            <br /><br />
            Whenever the trader opens a trade in their account, the same trade will be automatically opened in the investor's account in proportion to their balance. Similarly, when the trader closes a trade, the investor's trades will also be automatically closed at the same level.
            <br /><br />
            Users can view the opened trades in their accounts. They can change the TP/SL points and realize profits or close the trades whenever they wish.
            <br /><br />
            In partially closing trades, the trade does not continue with the trader's trade, and the remaining portion is exited from the MAM.
            <br /><br />
            The amount to be deposited to join the MAM system varies depending on the trader chosen by the investor.
            <br /><br />
            If the investor's balance is close to the chosen trader's balance, the Stop-Out risk is reduced. For example, if the investor's balance is 100%, all trades are opened one-to-one by the system. If it's 75%, 75% of the opened trades are executed. In other words, when the master account opens 1 lot, the following investor opens 0.75 lots. The investor's balance determines the percentage of the master account balance, and the opened trade is proportional to that percentage.
          </p>
        </div>
      </div>
    </>
  )
}

export default PammMam