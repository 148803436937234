import React from 'react'
import { SimpleCard } from '../components/Cards/SimpleCard.jsx'
//images
import icon1 from '../assets/icons/partnership-icon-1.svg'
import icon2 from '../assets/icons/partnership-icon-2.svg'
import icon3 from '../assets/icons/partnership-icon-3.svg'

export const AffiliateEasyStart = () => {
    return (
        <div className=' bg-[#1F252D]' >
            <div className='container-max mx-auto text-white text-center py-16' >
                <div className='mb-10' >
                    <h3 className='text-3xl font-semibold' >Easy Start with MonsTrade</h3>
                </div>
                <div className='grid md:grid-cols-3 grid-rows-1 mb-10'>
                    <div className='md:border-r-[1px] border-gray-400 p-10'>
                        <SimpleCard
                            icon={icon1}
                            header={'Marketing Support'}
                            description={'We offer a wide range of tools - banners, presentations, landing pages, images, etc.'}
                        />
                    </div>
                    <div className='md:border-r-[1px] border-gray-400 p-10' >
                        <SimpleCard
                            icon={icon2}
                            header={'Large Information Database'}
                            description={'To ensure your success as a Partner, we conduct webinars, organize conferences and forums, and hold discussions on various subjects'}
                        />
                    </div>
                    <div className='p-10' >
                        <SimpleCard
                            icon={icon3}
                            header={'Multilingual Support'}
                            description={'Each Partner is assigned to a qualified Personal manager, who can provide real assistance'}
                        />
                    </div>
                </div>
                <div>
                    <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='text-black bg-white px-4 py-2 rounded-full' >Become a Partner</a>
                </div>
            </div>
        </div>
    )
}
