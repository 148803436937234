import React from 'react'
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
//images
import bgImage from '../assets/images/banners/contact-us-banner.png'
import { ContactInfo } from '../containers/ContactInfo'
import { BannerContainerV2 } from '../components/Containers/BannerContainerV2'



export const ContactUs = () => {

    const landingOptions = {
      image : bgImage,
      imageGradient : "rgb(3 0 117 / 65%)",
      container : {
        width : 'w-2/3',
        padding : 'py-80'
      },
      header: {
        text : 'Your trade will be Fully Supported by our System',
        size: 'text-3xl',
      },
      subHeader: {
        text : "Whatever the question, we have the answer. Whatever the problem, we have the solution. Our award-winning team is the support you need to develop your trading career:",
      },
      button: {}
    }

  return (
    <div>
        <BannerContainerV2 options={landingOptions}/>
        <ContactInfo />
    </div>
  )
}
