import React from 'react'
import { DocumentationListcontainer } from '../containers/DocumentationListcontainer.jsx'
import bgImage from '../assets/images/documents-img.png'
import { BannerContainer } from '../components/Containers/BannerContainer'
import { BannerContainerV2 } from '../components/Containers/BannerContainerV2.jsx'

export const Documentation = () => {

  const landingOptions = {
    image : bgImage,
    container : {
      width : 'w-full',
      padding : 'py-2'
      
    },
    header: {
      text : 'Documents',
      size: 'text-5xl',
      align: "lg:text-left text-center"
    },
    subHeader: {
        text : "",
        align: "text-left"
    },
    button: {}
  }

  return (
    <div>
        <BannerContainerV2 options={landingOptions}/>
        <DocumentationListcontainer />
    </div>
  )
}
