import React from 'react';

export const SimpleCard = ({ header, link, description, icon, btnText, textClass }) => {
  return (
    <a href={link} key={header} className={`block ${textClass || ''}`}>
      <div className="grid grid-rows-[auto_auto_1fr] h-full">
        <div className="flex justify-center items-center p-4">
          <img 
            src={icon} 
            alt={`${header} icon`} 
            className="w-auto h-16 md:h-24 object-contain max-w-[80%]" 
          />
        </div>
        <div className="flex justify-center px-4">
          <p className="font-semibold text-lg md:text-xl text-center">{header}</p>
        </div>
        <div className="flex flex-col items-center justify-center p-4">
          {description && (
            <p 
              dangerouslySetInnerHTML={{ __html: description }} 
              className="text-center text-sm md:text-base mb-4"
            />
          )}
          {btnText && (
            <div className="mt-auto">
              <a 
                href="#anchor" 
                className="bg-monstrade-blue hover:bg-monstrade-green text-white px-4 py-2 rounded-md inline-block"
              >
                View Spreads
              </a>
            </div>
          )}
        </div>
      </div>
    </a>
  );
};

export default SimpleCard;