import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { GoogleMapContainer } from '../components/Map/GoogleMapContainer'
//images
import image from '../assets/images/contact-us-img.png'
import { ContactUsForm } from '../components/Forms/ContactUsForm'
export const ContactInfo = () => {
  return (
    <div className=' bg-[#F5F5F5]'>
    <div className='container-max mx-auto'>
        <div className='text-center py-16'>
          <h3 className='text-xl mb-6' ><FontAwesomeIcon icon="fa-solid fa-headset" className='text-mons-blue-1' /> Customer Support</h3>
          <p>For prompt response, please include your trading account number in your email.</p>
        </div>
        <div className='grid md:grid-cols-3 gap-5 mb-24'>
          <div className='bg-white rounded grid grid-cols-3 p-6'>
            <div className='' >
              <FontAwesomeIcon icon="fa-regular fa-clock" size='3x' className='text-gray-400' />
            </div>
            <div className='col-span-2' >
              <p className='text-gray-400' >Working Hours</p>
              <p>Mon - Fri 24 Hours</p>
            </div>
          </div>
          <div className='bg-white rounded grid grid-cols-3 p-6'>
            <div className='' >
              <FontAwesomeIcon icon="fa-regular fa-envelope" size='3x' className='text-gray-400' />
            </div>
            <div className='col-span-2' >
              <p className='text-gray-400' >Email</p>
              <p>support@monstrade.com</p>
            </div>
          </div>
          <div className='bg-white rounded grid grid-cols-3 p-6'>
            <div className='' >

              <FontAwesomeIcon icon="fa-brands fa-telegram" size='3x' className='text-gray-400' />
            </div>
            <div className='col-span-2' >
              <p className='text-gray-400' >Telegram</p>
              <p>Monstrade Support Telegram</p>
            </div>
          </div>
          <div className='bg-white rounded grid grid-cols-3 p-6'>
            <div className='' >
              <FontAwesomeIcon icon="fa-solid fa-circle-info" size='3x' className='text-gray-400' />
            </div>
            <div className='col-span-2' >
              <p className='text-gray-400' >All  Language Support</p>
              <p>+44 7450 1258 93</p>
            </div>
          </div>
          <div className='bg-white rounded grid grid-cols-3 p-6'>
            <div className='' >
              <FontAwesomeIcon icon="fa-solid fa-circle-info" size='3x' className='text-gray-400' />
            </div>
            <div className='col-span-2' >
              <p className='text-gray-400' >English  Language Support</p>
              <p>+44 7450 1258 93</p>
            </div>
          </div>
        </div>
        <div className='mb-20' >
          <div className='mb-10' >
            <h2 className='text-3xl font-semibold md:text-left text-center' >International Offices</h2>
          </div>
          <div className='grid md:grid-cols-2 md:justify-start justify-center gap-24' >
            <div className='flex-col px-5'>
              <div className='mb-4' >
                <p className='font-semibold text-lg' >Registration Address</p>
              </div>
              <div className='flex mb-8' >
                <div className='mr-4' >
                  <FontAwesomeIcon icon="fa-solid fa-location-dot" className='text-mons-blue-1' size='2x' />
                </div>
                <div className='flex-col' >
                  <div className='mb-8' >
                    <p className='font-semibold mb-2' >Monstrade Global Ltd</p>
                    <p className='text-normal' >Antifašističke borbe 29-E, Belgrad 11070, Serbia</p>
                  </div>
                </div>
              </div>
              <div className='mb-4' >
                <p className='font-semibold text-lg' >Representative Office</p>
              </div>
              <div className='flex' >
                <div className='mr-4' >
                  <FontAwesomeIcon icon="fa-solid fa-location-dot" className='text-mons-blue-1' size='2x' />
                </div>
                <div>
                  <p className='font-semibold mb-2' >Monstrade Global Ltd</p>
                  <p className='text-normal' >Str. Kozara No:68-2/6 1000 Skopje / North Macedonia</p>
                </div>
              </div>
            </div>
            <div>
              <GoogleMapContainer />
            </div>
          </div>
        </div>
        <div className='pb-20 flex flex-col' >
          <div className='text-center w-2/3 mx-auto mb-8'>
            <p className='lg:text-[28px] text-xl leading-[32px] font-semibold mb-6' >Your Voice Matters</p>
            <p className='lg:text-lg text-normal' >
              Reach out to MonsTrade, for feedback, questions, or anything else you have in mind. 
            </p>
          </div>
          <div className='mx-auto grid sm:grid-cols-3 gap-8'>
            <div className='overflow-hidden sm:rounded-2xl mx-auto' >
              <img src={image} alt="" />
            </div>
            <div className='sm:col-span-2' >
              <ContactUsForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}