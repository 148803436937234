import React, {useRef} from 'react'
import PammBanner from '../assets/images/banners/pamm-banner.png'
import PammBanner2 from '../assets/images/banners/pamm-banner-2.png'
import { BannerContainer } from "../components/Containers/BannerContainer";
import TextAreaWithIcon from '../components/TextAreaWithIcon';
import { MamPammForm } from '../components/Forms/MamPammForm';
import { Carousel } from 'react-responsive-carousel';
import imgM2 from '../assets/images/banners/pamm-banner-mobile.png'
import YouTube from 'react-youtube';
import Image1 from '../assets/images/pamm-image-1.png'

import Icon1 from '../assets/icons/blue-contacts.png'
import Icon2 from '../assets/icons/blue-phone.png'
import Icon3 from '../assets/icons/blue-eye.png'
import Icon4 from '../assets/icons/100-control.png'
import Icon5 from '../assets/icons/blue-star.png'
import Icon6 from '../assets/icons/one-click.png'

import BlueIcon1 from '../assets/icons/blue-1.png'
import BlueIcon2 from '../assets/icons/blue-2.png'
import BlueIcon3 from '../assets/icons/blue-3.png'

import Tick from '../assets/icons/green-tick.svg'
import UserImage from '../assets/icons/user-image.png'
import { BannerContainerV2 } from '../components/Containers/BannerContainerV2';

const landingOptions = {
    image: PammBanner,
    container: {
        width: 'md:w-3/6 w-full',
        padding: 'h-[580px]',
        margin: 'md:ml-0 m-auto flex',
        marginY: 'my-auto',
        textAlign: 'md:text-left text-center'
    },
    header: {
        text: 'PAMM Accounts for Profitable Investments',
        size: 'text-5xl',
        align: 'lg:text-left text-center',
        color: 'text-white',
    },
    subHeader: {
        text: "Diversify Your Forex Investments with Collective Funds, Avoiding a Single Style.",
        align: "lg:text-left text-center",
        color: "text-white"
    },
    button: {
        text: 'Request It',
        bgcolor: 'bg-monstrade-blue',
        textcolor: 'text-white hover:text-monstrade-blue',
        hover: 'hover:bg-white'
    }
}

const landingOptions2 = {
    image : PammBanner2,
    mobileImage: imgM2,
    container: {
        width: 'md:w-[400px] w-full',
        padding: 'h-[580px]',
        margin: 'md:ml-0 m-auto flex',
        marginY: 'my-auto',
        textAlign: 'md:text-left text-center'
    },
    header: {
        text : 'Join the Funds of the Best',
        size: 'text-4xl',
        bold: 'font-light',
        align: 'lg:text-left text-center pb-6',
        color : 'text-white',
      },
      header2: {
        text : "Benefit from collective funds with PAMM",
        size: 'text-5xl',
        bold: 'font-bold',
        align : "lg:text-left text-center pb-6",
        color : "text-white"
      },
      subHeader: {
        text : "PAMM & MAM are the Privilege of Monstar",
        size: 'text-xl underline',
        bold: 'font-base',
        align : "lg:text-left text-center",
        color : "text-white"
      },
}

const textAreaOptions = [
    {
        image: Icon1,
        header: "for Everyone",
        description: "Anyone, from beginners to advanced investors, can trade with PAMM or share their trades.",
    },
    {
        image: Icon2,
        header: "Simple to Use",
        description: "Utilizing the PAMM privilege is extremely easy. The interface is designed to be straightforward, providing simple access to statistics, trade statuses, and trade adjustments.",
    },
    {
        image: Icon3,
        header: "Control’s in Your Hands",
        description: "With PAMM, control is in the hands of the account manager, and you benefit from their skills.",
    },
    {
        image: Icon5,
        header: "User-friendly",
        description: "You can benefit from PAMM with extremely low commissions.",
    },
    {
        image: Icon6,
        header: "One Click",
        description: "With the ease of a single click, you can join a fund!",
    },
]

const textAreaOptions2 = [
    {
        image: BlueIcon1,
        header: "Register & verify your account",
        description: "Complete our registration form and verify your account.",
    },
    {
        image: BlueIcon2,
        header: "Request a PAMM/MAM account",
        description: "Send a request for a Money Manager PAMM/MAM account to support@monstrade.com",
    },
    {
        image: BlueIcon3,
        header: "Add accounts - start trading",
        description: "Register your investor accounts and set the terms and simply start trading.",
    },

]

const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
}


export const Pamm = () => {

  const windowWidth = useRef(window.innerWidth);
  
  const opts = {
    height: '390',
    width: windowWidth.current>600 ? '600' : (windowWidth.current-30),
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
    },
  };

  return (
    <>
        <Carousel autoPlay={false} infiniteLoop={true} interval={3000} showThumbs={false}>
            <BannerContainerV2 options={landingOptions} />
            <BannerContainerV2 options={landingOptions2} />
        </Carousel>
        <div className='text-center text-[#486175] py-12'>
            <p className='text-3xl font-bold pb-4'>What is PAMM?</p>
            <p className='lg:w-3/4 mx-auto'>PAMM accounts offer a unique opportunity for investors to consolidate their funds and engage in financial market activities. This type of account not only provides investors with increased trading options but also presents the potential for higher returns. PAMM encompasses automated trading systems, robust risk management tools, and sophisticated analysis and reporting features. Individuals who seek less hands-on control over their investments and prefer a more hands-off approach may find PAMM accounts appealing, especially when compared to the higher control demands of MAM (Multi-Account Manager) accounts.</p>
        </div>
        <div className='bg-[#E6F9FF] text-[#486175] lg:text-left text-center'>
            <div className='grid lg:grid-cols-2 gap-4 items-center py-16 lg:mx-24 mx-4'>
                <div>
                    <p className='text-3xl font-bold'>How PAMM Works</p>
                    <p className='py-4'>With the privilege of PAMM investment, be an investor, manager, or both!<br/><br/>MonsTrade brings traders and investors together under mutually beneficial conditions through PAMM. It allows experienced investors to become Managers and earn profits by attracting investors. At the same time, traders can become Investors and make a profit without having extensive trading knowledge<br/><br/>Investors become part of a manager's fund and earn returns in proportion to their shares.</p>
                    <a className="bg-monstrade-blue flex lg:mx-0 mx-auto rounded lg:w-[240px] overflow-hidden" href="">
                        <span className="md:w-full w-40 text-white p-2 px-3 lg:mx-0 mx-auto hover:bg-monstrade-green font-bold text-center" >Request PAMM Account</span>
                    </a>
                </div>
                <div>
                    <img className='mx-auto' src={Image1} alt="" />
                </div>
            </div>
        </div>
        <div>
            <div className="grid lg:grid-cols-6 justify-items-center gap-4 gap-y-12 lg:gap-y-20 lg:gap-x-8 mx-8 py-12">
                <div className="rounded-md w-full shadow-2xl p-6 lg:col-span-2">
                    <div className='h-24' >
                        <img className='mx-auto -mt-12' src={textAreaOptions[0].image} alt="" />
                    </div>
                    <div className="h-20" >
                        <p className='text-center font-semibold text-xl py-4'>{textAreaOptions[0].header}</p>
                    </div>
                    <div>
                        <p className='mx-auto text-center'>{textAreaOptions[0].description}</p>
                    </div>
                </div>

                    <div className="rounded-md w-full shadow-2xl p-6 lg:col-span-2">
                        <div className='h-24' >
                            <img className='mx-auto -mt-12' src={textAreaOptions[1].image} alt="" />
                        </div>
                        <div className="h-20" >
                            <p className='text-center font-semibold text-xl py-4'>{textAreaOptions[1].header}</p>
                        </div>
                        <div>
                            <p className='mx-auto text-center'>{textAreaOptions[1].description}</p>
                        </div>
                    </div>

                    <div className="rounded-md w-full shadow-2xl p-6 lg:col-span-2">
                        <div className='h-24' >
                            <img className='mx-auto -mt-12' src={textAreaOptions[2].image} alt="" />
                        </div>
                        <div className="h-20" >
                            <p className='text-center font-semibold text-xl py-4'>{textAreaOptions[2].header}</p>
                        </div>
                        <div>
                            <p className='mx-auto text-center'>{textAreaOptions[2].description}</p>
                        </div>
                    </div>

                    <div className="rounded-md w-full shadow-2xl p-6 lg:col-start-2 lg:col-end-4">
                        <div className='h-24' >
                            <img className='mx-auto -mt-12' src={textAreaOptions[3].image} alt="" />
                        </div>
                        <div className="h-20" >
                            <p className='text-center font-semibold text-xl py-4'>{textAreaOptions[3].header}</p>
                        </div>
                        <div>
                            <p className='mx-auto text-center'>{textAreaOptions[3].description}</p>
                        </div>
                    </div>

                <div className="rounded-md w-full shadow-2xl p-6 lg:col-span-2">
                    <div className='h-24' >
                        <img className='mx-auto -mt-12' src={textAreaOptions[4].image} alt="" />
                    </div>
                    <div className="h-20" >
                        <p className='text-center font-semibold text-xl py-4'>{textAreaOptions[4].header}</p>
                    </div>
                    <div>
                        <p className='mx-auto text-center'>{textAreaOptions[4].description}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='py-16'>
            <div className='text-center'>
                <p className='font-bold text-3xl bg-gradient-to-b from-monstrade-blue to-blue-300 inline-block text-transparent bg-clip-text'><span className='text-black'>Join in</span> Just 3 Steps</p>
            </div>
            <div className="grid lg:grid-cols-3 justify-items-center gap-4 lg:gap-y-20 lg:gap-x-8 mx-8 py-12">
                {textAreaOptions2.map( (item) => {
                    return(
                        <div className="rounded-md w-full text-center p-6">
                            <div className='h-24' >
                                <img className='mx-auto' src={item.image} alt="" />
                            </div>
                            <div className="h-8 font-bold" >
                                <p>{item.header}</p>
                            </div>
                            <div>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    )
                } ) }
            </div>
            <a className="bg-monstrade-blue flex rounded w-[150px] mx-auto overflow-hidden" href="">
                <span className="md:w-full w-40 text-white p-2 px-3 hover:bg-monstrade-green font-bold text-center" >Request Today</span>
            </a>
        </div>
        <div className="pb-20">
                <p className="text-3xl font-bold text-center pb-6">MAM vs PAMM - What's the difference?</p>
                <div className="grid lg:grid-cols-2 gap-4 lg:mx-24 mx-4 lg:text-left text-center">
                    {/* MAM Section */}
                    <div className="text-[#7B7B7B] flex flex-col h-full">
                        <div className="bg-monstrade-blue text-white font-bold p-3 flex-none h-14 flex items-center">
                            MAM (Multi-Account Manager)
                        </div>
                        <div className="bg-[#F5F5F5] p-3 flex-1 flex items-center min-h-[72px]">
                            Allows traders to seamlessly execute trades across multiple client accounts.
                        </div>
                        <div className="bg-[#E6E6E6] p-3 flex-1 flex items-center min-h-[72px]">
                            Funds in client sub-accounts are pooled together to provide capital for trading.
                        </div>
                        <div className="bg-[#F5F5F5] p-3 flex-1 flex items-center min-h-[72px]">
                            Through a master account, traders plan and execute trades that are automatically replicated across client sub-accounts.
                        </div>
                        <div className="bg-[#E6E6E6] p-3 flex-1 flex items-center min-h-[72px]">
                            Investors who hold sub-accounts can act independently to close trades, deposit funds or make withdrawals at any time.
                        </div>
                    </div>

                    {/* PAMM Section */}
                    <div className="text-[#7B7B7B] flex flex-col h-full">
                        <div className="bg-monstrade-blue text-white font-bold p-3 flex-none h-14 flex items-center">
                            PAMM (Percentage Allocation Management Module)
                        </div>
                        <div className="bg-[#F5F5F5] p-3 flex-1 flex items-center min-h-[72px]">
                            Allows traders to seamlessly execute trades across multiple client accounts.
                        </div>
                        <div className="bg-[#E6E6E6] p-3 flex-1 flex items-center min-h-[72px]">
                            Investors and traders pool their funds together to trade.
                        </div>
                        <div className="bg-[#F5F5F5] p-3 flex-1 flex items-center min-h-[72px]">
                            Investors and traders share in profits or losses in proportion to their share of the pool.
                        </div>
                        <div className="bg-[#E6E6E6] p-3 flex-1 flex items-center min-h-[72px]">
                            Investors only make investment decisions at the start of each investing round, after profits or losses from the previous round have been settled.
                        </div>
                    </div>
                </div>
            </div>
        <div className='bg-[#F0F5FB] py-28'>
                <div className='grid lg:grid-cols-2 gap-8 lg:mx-24 mx-4 items-center justify-center'>
                    <div className='lg:text-left text-center max-w-[500px] mx-auto'>
                        <p className='text-3xl font-bold pb-4'>Trading and Transaction<br /> <span className='bg-gradient-to-b from-cyan-500 to-blue-200 inline-block text-transparent bg-clip-text'>Transparency</span></p>
                        <p className='text-[#7B7B7B] '>Skilling facilitates the trading and transactional activities that together make up the complete mechanism of money management for online trading.</p>
                    </div>
                    <div>
                        <ul>
                            <li className='pb-2'><img className='inline-block' src={Tick} alt="" />Platform reliability and security</li>
                            <li className='pb-2'><img className='inline-block' src={Tick} alt="" />Transparency in transaction and performance review</li>
                            <li className='pb-2'><img className='inline-block' src={Tick} alt="" />Raw price connectivity</li>
                            <li className='pb-2'><img className='inline-block' src={Tick} alt="" />Exceptional order execution across all orders</li>
                            <li><img className='inline-block' src={Tick} alt="" />Real time</li>
                        </ul>
                    </div>
                </div>
            </div>
        <div className='max-w-[800px] mx-auto p-5'>
                <div className='grid gap-4 items-center justify-center py-16'>
                    <p className='text-3xl text-center mx-auto pb-8'>PAMM & MAM are each a Monstar Privilege</p>
                    <div className='mx-auto w-full overflow-hidden'>
                        <YouTube videoId="Y6jvF0KJX0c" opts={opts} onReady={onPlayerReady} />;
                    </div>
                </div>
            </div>
        <div className='bg-[#F5F5F7] py-16'>
            <p className='text-3xl text-center'>Monstrade Social Trading Edge</p>
            <p className='text-center'>Here are the Top Ranked Master Traders as of Today</p>

                <table className='lg:w-3/4 lg:mx-auto my-8 rounded-2xl'>
                    <thead className='bg-[#DAE0E5] md:text-md text-sm'>
                        <tr>
                            <td colSpan={4} className='text-xl font-bold p-2'>TOP 10 Master Trader</td>
                        </tr>
                        <tr>
                            <td className='py-4'>#Number</td>
                            <td className='py-4'>Master Trader Name</td>
                            <td className='py-4'>Yield Rate</td>
                            <td className='py-4'></td>
                        </tr>
                    </thead>
                    <tbody className='bg-white md:text-md text-sm'>
                        <tr>
                            <td className='font-bold md:pl-6 text-xl'>1</td>
                            <td className='py-4'>
                                <div className='inline-block pr-2'>
                                    <img className='max-w-[40px]' src={UserImage} alt="" />
                                </div>
                                <div className='inline-block'>
                                    <p className='font-bold w-[100px]'>TradeMaster10</p>
                                    <p>459821</p>
                                </div>
                            </td>
                            <td className='text-[#45CC39] font-bold'>+1078.55%</td>
                            <td><button className='bg-gray-300 hover:bg-gradient-to-b from-blue-600 to-blue-300 p-2 text-white shadow-md px-5 rounded-3xl'>COPY NOW</button></td>
                        </tr>

                        <tr>
                            <td className='font-bold md:pl-6 text-xl'>2</td>
                            <td>
                                <div className='inline-block pr-2'>
                                    <img className='max-w-[40px]' src={UserImage} alt="" />
                                </div>
                                <div className='inline-block'>
                                    <p className='font-bold w-[100px]'>CopyKing</p>
                                    <p>783695</p>
                                </div>
                            </td>
                            <td className='text-[#45CC39] font-bold'>+1048.33%</td>
                            <td><button className='bg-gray-300 hover:bg-gradient-to-b from-blue-600 to-blue-300 p-2 text-white shadow-md px-5 rounded-3xl'>COPY NOW</button></td>
                        </tr>

                        <tr>
                        <td className='font-bold md:pl-6 text-xl'>3</td>
                        <td className='py-2'>
                            <div className='inline-block pr-2'>
                                <img className='max-w-[40px]' src={UserImage} alt="" />
                            </div>
                            <div className='inline-block'>
                                <p className='font-bold w-[100px]'>InvestmentHero</p>
                                <p>246810</p>
                            </div>
                        </td>
                        <td className='text-[#45CC39] font-bold'>+563.2%</td>
                        <td><button className='bg-gray-300 hover:bg-gradient-to-b from-blue-600 to-blue-300 p-2 text-white shadow-md px-5 rounded-3xl'>COPY NOW</button></td>
                    </tr>

                    <tr>
                        <td className='font-bold md:pl-6 text-xl'>4</td>
                        <td className='py-2'>
                            <div className='inline-block pr-2'>
                                <img className='max-w-[40px]' src={UserImage} alt="" />
                            </div>
                            <div className='inline-block'>
                                <p className='font-bold w-[100px]'>TrendTracker</p>
                                <p>135790</p>
                            </div>
                        </td>
                        <td className='text-[#45CC39] font-bold'>+495.58%</td>
                        <td><button className='bg-gray-300 hover:bg-gradient-to-b from-blue-600 to-blue-300 p-2 text-white shadow-md px-5 rounded-3xl'>COPY NOW</button></td>
                    </tr>

                    <tr>
                        <td className='font-bold md:pl-6 text-xl'>5</td>
                        <td className='py-2'>
                            <div className='inline-block pr-2'>
                                <img className='max-w-[40px]' src={UserImage} alt="" />
                            </div>
                            <div className='inline-block'>
                                <p className='font-bold w-[100px]'>BuySellBot</p>
                                <p>864209</p>
                            </div>
                        </td>
                        <td className='text-[#45CC39] font-bold'>+410.10%</td>
                        <td><button className='bg-gray-300 hover:bg-gradient-to-b from-blue-600 to-blue-300 p-2 text-white shadow-md px-5 rounded-3xl'>COPY NOW</button></td>
                    </tr>

                    <tr>
                        <td className='font-bold md:pl-6 text-xl'>6</td>
                        <td className='py-2'>
                            <div className='inline-block pr-2'>
                                <img className='max-w-[40px]' src={UserImage} alt="" />
                            </div>
                            <div className='inline-block'>
                                <p className='font-bold w-[100px]'>StockSultan</p>
                                <p>572193</p>
                            </div>
                        </td>
                        <td className='text-[#45CC39] font-bold'>+226.87%</td>
                        <td><button className='bg-gray-300 hover:bg-gradient-to-b from-blue-600 to-blue-300 p-2 text-white shadow-md px-5 rounded-3xl'>COPY NOW</button></td>
                    </tr>

                    <tr>
                        <td className='font-bold md:pl-6 text-xl'>7</td>
                        <td className='py-2'>
                            <div className='inline-block pr-2'>
                                <img className='max-w-[40px]' src={UserImage} alt="" />
                            </div>
                            <div className='inline-block'>
                                <p className='font-bold w-[100px]'>InvestmentStar</p>
                                <p>937561</p>
                            </div>
                        </td>
                        <td className='text-[#45CC39] font-bold'>+177.1%</td>
                        <td><button className='bg-gray-300 hover:bg-gradient-to-b from-blue-600 to-blue-300 p-2 text-white shadow-md px-5 rounded-3xl'>COPY NOW</button></td>
                    </tr>

                    <tr>
                        <td className='font-bold md:pl-6 text-xl'>8</td>
                        <td className='py-2'>
                            <div className='inline-block pr-2'>
                                <img className='max-w-[40px]' src={UserImage} alt="" />
                            </div>
                            <div className='inline-block'>
                                <p className='font-bold w-[100px]'>CopyCaptain</p>
                                <p>618402</p>
                            </div>
                        </td>
                        <td className='text-[#45CC39] font-bold'>+173.03%</td>
                        <td><button className='bg-gray-300 hover:bg-gradient-to-b from-blue-600 to-blue-300 p-2 text-white shadow-md px-5 rounded-3xl'>COPY NOW</button></td>
                    </tr>

                    <tr>
                        <td className='font-bold md:pl-6 text-xl'>9</td>
                        <td className='py-2'>
                            <div className='inline-block pr-2'>
                                <img className='max-w-[40px]' src={UserImage} alt="" />
                            </div>
                            <div className='inline-block'>
                                <p className='font-bold w-[100px]'>QuickHedge</p>
                                <p>359184</p>
                            </div>
                        </td>
                        <td className='text-[#45CC39] font-bold'>+158.0%</td>
                        <td><button className='bg-gray-300 hover:bg-gradient-to-b from-blue-600 to-blue-300 p-2 text-white shadow-md px-5 rounded-3xl'>COPY NOW</button></td>
                    </tr>

                    <tr>
                        <td className='font-bold md:pl-6 text-xl'>10</td>
                        <td className='py-2'>
                            <div className='inline-block pr-2'>
                                <img className='max-w-[40px]' src={UserImage} alt="" />
                            </div>
                            <div className='inline-block'>
                                <p className='font-bold w-[100px]'>TradeTailor</p>
                                <p>205847</p>
                            </div>
                        </td>
                        <td className='text-[#45CC39] font-bold'>+155.64%</td>
                        <td><button className='bg-gray-300 hover:bg-gradient-to-b from-blue-600 to-blue-300 p-2 text-white shadow-md px-5 rounded-3xl'>COPY NOW</button></td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className='bg-monstrade-blue py-16'>
                <div className='grid lg:grid-cols-2 lg:text-left text-center items-center lg:mx-32 mx-6'>
                    <div className='text-white'>
                        <p className='text-5xl pb-4 font-bold'>Request a PAMM Account</p>
                        <p className='text-xl'>Send your contact information to have a PAMM account.</p>
                    </div>
                    <div>
                        <p className='text-white pb-2'>Fill the form to request a PAMM account</p>
                        <MamPammForm />
                    </div>
                </div>
            </div>

        </>
    )
}
