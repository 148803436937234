import React from 'react'
import { DocumentationCard } from '../components/Cards/DocumentationCard'

export const DocumentationListcontainer = () => {
    const docs = [
        {   header: 'Privacy policy', 
            text : 'To open an account, the client is required to go through '+
                    'this document and accept its terms and conditions.',
            link: '/privacy-policy'
        },
        { 
            header: 'Anti-money laundering (AML) policy',
            text : 'To open an account, the client is required to go through '+
                    'this document and accept its terms and conditions.',
            link : '/aml-policy'
        },
        { 
            header: 'Processing of personal data terms and conditions',
            text : 'This document describes the policy regarding processing and '+
                    'storage of personal data of Monstrade website users.',
            link : '/terms-and-conditions'
        },
        { 
            header: 'Regulations on non-trading operations',
            text : 'The document establishes the procedure and principles for performing '+
                    'non-trading operations on the Client account with Monstrade Ltd.',
            link : '/about/company/regulations'
        },
        { 
            header: 'Wrong transfers cancellation and refund policy',
            text : 'This document describes the Company’s refund procedure and the actions '+
                    'that must be taken by the Client in the event of a wrong money transfer.',
            link : '/campaign/trade-refund'
        },
        { 
            header: 'Certificate of trademark registration',
            text : 'Monstrade has registered its trademark. View the registration certificate here.',
            link : '/about/company/regulations'
        },
        { 
            header: 'Cookies policy',
            text : 'Here you will find information about how we use and store cookies.',
            link : '/privacy-policy'
        },
        { 
            header: 'Regulations on transferring positions to Monstrade',
            text : 'Here you will find information about Regulations on transferring positions to Monstrade.',
            link : '/about/company/regulations'
        },
        { 
            header: 'Copy trading service regulations',
            text : 'Definition and features of the copy trading service.',
            link : '/campaign/copy-trade'
        },
    ]
  return (
    <div className=' bg-[#F1F4F5] ' >
        <div className='container-max mx-auto py-24'>
            <div className='grid md:grid-cols-2 gap-10 my-10'>
                { docs.map( (item) => {
                    return <DocumentationCard header={item.header} text={item.text} link={item.link}/>
                })}
            </div>
        </div>
    </div>
  )
}
