import React from 'react'
import Banner from '../../assets/images/banners/account-change-banner.png'
import CampaignBanner from '../../components/Banners/CampaignBanner'

const AccountChange = () => {
  return (
    <>
    
      <CampaignBanner
        header='| MONSTRADE PRIVILEGES'
        header2='Account Change'
        desc='Forex trading involves high risks and may result in the loss of your entire investment. Avoid trading without adequate knowledge.'
        Banner={Banner}
      />
      <div className='bg-[#F3F5F6] p-16 text-[#787878]'>
        <div className='mx-auto bg-white p-16'>
          <p className='text-3xl font-bold pb-4'>Campaign Details</p>
          <p>
            An investor with the Account Change privilege moves to a higher investor category for a one-week period.
            <br /><br />
            After the completion of the one-week period, the investor continues to receive services in the previous investor category.
            <br /><br />
            To benefit from the Account Change privilege, the investor needs to request this option from their investment consultant or institutional support lines.
            <br /><br />
            An investor wishing to take advantage of the Account Change privilege continues to receive services in the previous existing category if they withdraw the entire amount or a certain portion of the amount deposited within the specified condition during the one-week period.
            <br /><br />
            The investor must not have any active transactions in their current investor category to use the Account Change privilege.
            <br /><br />
            With this application, the investor benefits from the conditions of the changed category if they also use other investor applications.
            <br /><br />
            Within the scope of Account Change, an investor can only move to the next higher category and benefit from the privileges of that level. There is no situation where the investor transitions to multiple levels within the scope of the utilized condition.
            <br /><br />
            In case of any misuse, Monstrade reserves the right to ban the investor from the application.
          </p>
        </div>
      </div>
    </>
  )
}

export default AccountChange