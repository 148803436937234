import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SilverDownload from '../assets/icons/silver-download.svg'
import GoldDownload from '../assets/icons/gold-download.svg'
import DiamondDownload from '../assets/icons/diamond-download.svg'

import SilverDoc from '../assets/documents/Silver_Winners.pdf'
import GoldDoc from '../assets/documents/Gold_Winners.pdf'
import DiamondDoc from '../assets/documents/Diamond_Winners.pdf'

export const XMasRewards = () => {
  const cards = [
    {
      doc: SilverDoc,
      title: 'SILVER TICKET',
      borderColor: 'border-gray-400',
      gradient: 'bg-gradient-to-b from-gray-400 via-white to-gray-400',
      titleColor: 'text-gray-600',
      categoryColor: 'text-gray-600',
      icon: SilverDownload
    },
    {
      doc: GoldDoc,
      title: 'GOLD TICKET',
      borderColor: 'border-yellow-400',
      gradient: 'bg-gradient-to-b from-yellow-400 via-yellow-200 to-yellow-600',
      titleColor: 'text-yellow-800',
      categoryColor: 'text-yellow-800',
      icon: GoldDownload
    },
    {
      doc: DiamondDoc,
      title: 'DIAMOND TICKET',
      borderColor: 'border-cyan-400',
      gradient: 'bg-gradient-to-b from-cyan-100 to-cyan-800',
      titleColor: 'text-cyan-800',
      categoryColor: 'text-cyan-50',
      icon: DiamondDownload
    }
  ];

  return (
    <div className="bg-red-900 py-16 px-4">
      <div className="text-center text-white mb-8">
        <p className="text-red-500 font-semibold text-xl sm:text-2xl">2025 BIG NEW YEAR'S DRAW</p>
        <h1 className="text-3xl sm:text-5xl font-bold py-2">WINNERS & REWARDS</h1>
        <p className="text-base sm:text-lg">Download the documents to see the results of the draw</p>
      </div>

      <div className="flex flex-row flex-wrap justify-center gap-4 sm:gap-8 max-w-5xl mx-auto">
        {cards.map((card) => (
          <div key={card.title} className="w-[30%] min-w-[120px] max-w-[185px]">
            <a href={card.doc} download className="block">
              <div className={`h-40 sm:h-44 w-full rounded-lg border-2 ${card.borderColor} ${card.gradient} p-2 flex flex-col items-center justify-around`}>
                <div className="text-center">
                  <p className={`font-semibold text-xs sm:text-sm ${card.titleColor}`}>{card.title}</p>
                  <p className={`font-semibold text-base sm:text-xl mt-1 ${card.categoryColor}`}>
                    CATEGORY<br />WINNER
                  </p>
                </div>
                <img className="w-6 sm:w-8" src={card.icon} alt="" />
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}