import React from 'react'
import { FAQCollapseButton } from '../components/Collapse/FAQCollapseButton'
import { FAQInnerCollapse } from '../components/Collapse/FAQInnerCollapse'

export const FAQ = () => {
    const accountsQ10 = "· Click the ‘Register’ button on this page and open your New Monstrade account. Fill out the form with your name, country of residence and email. Set the password.<br/>· Specify your nationality, date of birth and mobile number.<br/>· Then, your residential address is required.<br/>· To act in your best interest, we kindly ask to specify your occupation and education level.<br/>· To understand your needs better, we should know more about your financial status and investment plans.<br/>· Your trading experience also matters to us! Please answer carefully, as your responses will determine your suitability to trade CFD with Monstrade.<br/>· The next important step is a small test for financial markets knowledge.<br/>· Now, put the default trading account settings. Later, you can open additional real and demo accounts with other preferences at any time.<br/>· Right after that, you may undergo the identity verification by uploading the digital copies of the documents required.<br/>· Don’t forget to check your inbox! The Monstrade Team will send you an email with account credentials. Please keep them safe. You will be able to fund your account once it gets verified to start trading with Monstrade."
    const verificationQ4 = "· Sign in to Monstrade with your email and password.<br/>· Get ready to take a photo of your passport, ID card or driver’s license.(A Proof of residence, such as a bank statement or utility bill, issued within the last 6 months, may also be required).<br/>· Click the “Upload documents” button and choose the row with the document you prefer to provide.Document verification is usually very fast and you can always check the status from the same section in Monstrade. Please note that uploading the documents through Direct is the only acceptable channel of submitting these documents and that any documents sent by email will not be processed."
    const content = [
        {
            header: 'About Company',
            questions: [
                {
                    question: 'How long has Monstrade been in business?',
                    answer: 'Monstrade Limited was founded by a Dubai-based group of asset managers who have been in the financial sector for 15 years, providing data services to large financial institutions, financially supporting fintech start-ups, and providing liquidity to financial institutions. Ten years ago, in 2012, with suitable investments, Monstrade Limited has grown over time to become one of the leading forex brokerage houses with over $100 million in equity.                    '
                },
                {
                    question: 'What are the advantages of trading with Monstrade?',
                    answer: 'Monstrade is a No Dealing Desk Execution broker, offering professional trading conditions, superior execution technology and access to deep liquidity.Trade CFDs on a wide range of instruments, including popular FX pairs, Futures, Indices, Metals, Energy and Shares, and experience the global markets at your fingertips.Monstrade also offers free educational material, forex trading tools and demo trading accounts to all of our clients to help them practice and learn more about CFDs trading before opening a live trading account. Monstrade customer support team is ready to help you 24/7 with any queries you may have in more than 12 languages.'
                },
                {
                    question: 'Where are your offices?',
                    answer: "Monstrade is headquartered in Melbourne, Australia, and has over 400 employees in offices in Australia, England, and Hong Kong. As a strong advocate of transparency, we set the highest security standards for our client's funds."
                },
                {
                    question: 'Who are your regulators?',
                    answer: 'Monstrade is also a member of Financialcommission.org and each client is protected by up to €20,000. Monstrade and its subsidiaries are trading at US$5.4bn daily, and over 200,000 investors worldwide trust us.'
                },
                {
                    question: 'How can I register as an Introducer?',
                    answer: "Fill in our online form with your contact details and some basic information (e.g. your website (if applicable), number of potential clients, etc) and one of our representatives will contact you to discuss this further. Find more information regarding our partnership options via this link:https://www.monstrade.com/partners"
                },
                {
                    question: 'When and how are IB commissions paid?',
                    answer: "Commissions are credited to your Monstrade Wallet on a weekly basis. You may use bank wire transfer to withdraw your commissions."
                }
            ]
        },
        {
            header: 'Accounts',
            questions: [
                {
                    question: 'How do I open a live trading account?',
                    answer: "To register for a live trading account, click on the 'Register' icon on the Monstrade homepage and follow the steps required to complete the sign-up process. You will be asked to upload your ID document(s) during the process, or you can upload them later via Monstrade. Once registered, you can proceed to fund your account and start trading on any one of our platforms. Please note that the jurisdictions available to you will depend on your country of residence. Monstrade does not offer Contracts for Difference(CFDs) to residents of certain countries such as the United States of America and the Islamic Republic of Iran."
                },
                {
                    question: 'Can I open a corporate account?',
                    answer: "You can open a trading account in your company's name using our usual registration procedure. Please enter the personal information of the person who will be the authorized representative and then the certificate of incorporation, articles of association, etc. You can send official company documents to info@monstrade.com. After receiving all required documents, our Back Office Department will review it. and helps to complete the application."
                },
                {
                    question: 'Do you offer swap-free accounts?',
                    answer: "Monstrade offers swap-free accounts for religious purposes. However, fees may be applied once trades on certain instruments are open for a specific number of days. To apply for a swap-free account, please send an email request to info@monstrade.com. For further details on Monstrade swap-free accounts, please contact our Customer Support."
                },
                {
                    question: 'Can I open a joint account?',
                    answer: "Yes. To open a joint account, each person must first open an individual Monstrade account and then fill a Joint Account Request Form which can be obtained by contacting our info@monstrade.com.Please note that joint accounts are only available to married couples or first degree relatives."
                },
                {
                    question: 'Can I open more than one account with Monstrade?',
                    answer: "Yes, Monstrade allows up to 5 different trading accounts. You may open additional trading accounts via your Monstrade."
                },
                {
                    question: 'What base currencies can I open an account in?',
                    answer: "Clients of Monstrade Limited may open a trading account in EUR, USD, GBP, AUD, CHF, JPY, PLN and ZAR.It is recommended that you select a Wallet currency in the same currency of your deposits and withdrawals in order to avoid any conversion fees, however you can select different base currencies for your Trading Accounts. When transferring between Wallet and an account in a different currency, a live conversion rate will be displayed to you."
                },
                {
                    question: 'What is a Premium (VIP) account?',
                    answer: "VIP Premium Accounts are offered to selected individuals with high deposits (typically $50k or equivalent) and those actively trading large volumes. VIP clients benefit from free VPS service and the possibility of decreased spreads/commissions."
                },
                {
                    question: 'Do you offer demo accounts?',
                    answer: "Yes. Demo accounts are a valuable trading tool to help beginners learn how to trade and allow experienced traders to experiment and test new strategies in a risk-free environment. You can open demo accounts via Monstrade.Although demo accounts present real market conditions and prices, please keep in mind that they are simulations and cannot always reasonably reflect all of the market conditions as during highly volatile or illiquid periods (e.g. market openings, news announcements) they may not behave in the same manner as live accounts."
                },
                {
                    question: 'Are demo accounts limited in any way?',
                    answer: "You may open as many demo accounts as you wish. Demo accounts can be recharged with funds via Monstrade. However, please note that demo accounts will be deactivated after 180 days. Also, our MT5 demo accounts are limited to a maximum of 70 open positions.Although demo accounts present real market conditions and prices, please keep in mind that they are simulations and cannot always reasonably reflect all of the market conditions as during highly volatile or illiquid periods (e.g. market openings, news announcements) they may not behave in the same manner as live accounts.Margin and leverage settings may vary between your demo and live account and you should not expect any success with the Demo Account to be replicated in your live trading.For this reason, it is strongly recommended that demo accounts are viewed solely as a learning tool for inexperienced traders or a place for testing new trading strategies."
                },
                {
                    question: 'How to open an account with Monstrade?',
                    answer: <div dangerouslySetInnerHTML={{__html: accountsQ10}} />
                }
            ]
        },
        { 
            header: 'Verification', 
            questions: [
                {
                    question: 'Are my personal details secure with you?',
                    answer: "Monstrade takes serious precautionary measures to ensure that your personal details are held in absolute confidence. Your passwords are encrypted and your personal details are stored on secure servers and cannot be accessed by anyone, with the exception of a very small number of authorised members of staff."
                },
                {
                    question: 'What documents do you require?',
                    answer: "We require a copy of your valid International Passport, National ID card or Driver's Licence in order to verify your identity.We may also request a Proof of residence document showing your name and address, issued within the last 6 months.The document(s) required and their current verification status can be seen at any time via Monstrade."
                },
                {
                    question: 'How can I upload or send my documents to you?',
                    answer: "You can upload your documents at the time of your registration. You can also upload documents via Monstrade by clicking on 'My Profile' and then 'Upload documents'."
                },
                {
                    question: 'How can I verify my profile?',
                    answer: <div dangerouslySetInnerHTML={{__html: verificationQ4}} />
                },
                {
                    question: 'What should I do if I fail the appropriateness test?',
                    answer: "As a regulated broker, we are required to assess the suitability of our clients in regards to their understanding of CFDs and knowledge of the risks involved.If it is deemed that you do not currently have the experience required, you can proceed with a demo account creation. Once you feel that you are ready and experienced enough to open a live account, and are fully aware of the risks involved, please contact us so we can reassess your suitability.If the information you provided to us on the registration form was inaccurate, please let us know so we can contact you to clarify any errors."
                }
            ]
        },
        { 
            header: 'Deposit and Withdrawals',
            questions: [
                {
                    question: 'Are any additional documents required to deposit in Cryptocurrencies?',
                    answer: "Depending on the amount of the transaction, you may be requested to upload your ID document for payment verification and/or the source of funds document (usually a screenshot from the Exchange that you are using, i.e. Crypto Wallet transaction statement)."
                },
                {
                    question: 'Are there any fees for withdrawals in cryptocurrencies?',
                    answer: "Monstrade does not charge any fees for transfers to and from MonsTrade Wallets for Crypto deposits/withdrawals, however fees may apply by the Crypto Provider(s)."
                },
                {
                    question: 'Can I transfer my money from trading account if I have an open position(s)?',
                    answer: "Yes, you can. However, at the moment of transfer from trading account to Wallet, your free margin must exceed the requested amount. Free margin is calculated as equity minus the necessary margin (which is required to maintain an open position).If you do not have sufficient free margin on your trading account, a transfer from trading account to Wallet will not be executed until you submit the correct amount of transfer and/or close the open positions on your account.Withdrawal from a Monstrade Wallet can be requested any time."
                },
                {
                    question: 'Can I withdraw via a different payment method from the one I used to deposit?',
                    answer: "Monstrade’s policy is to process withdrawals via the same method that you used to deposit. For example, if you deposited using a credit card, the card will be credited with the amount equal to the deposit amount. Upon request, we can send any profits via other payment methods under your name."
                },
                {
                    question: 'Do I have to use the same Crypto Wallet and cryptocurrency to withdraw as the one used to make a deposit to my Monstrade Wallet?',
                    answer: "You can use different cryptocurrencies to deposit and withdraw within the list of cryptocurrencies offered by MonsTrade as long as the Crypto Wallets lawfully belong to you."
                },
                {
                    question: 'Do you have any charges on deposits and withdrawals?',
                    answer: "Monstrade has a zero fees policy on deposits and withdrawals."
                },
                {
                    question: 'Do you have any charges on deposits and withdrawals?',
                    answer: "Monstrade has a zero fees policy on deposits and withdrawals.."
                },
                {
                    question: 'How do I deposit funds to my account?',
                    answer: "You can make a deposit inside your Client Area using a funding option that suits you best. There are no fees on deposits."
                },
                {
                    question: 'How do I withdraw funds from my account?',
                    answer: "Log in to your Client Area and fill in the respective withdrawal form. There are no fees on withdrawals."
                },
                {
                    question: 'How fast do you process my withdrawals?',
                    answer: "We process all withdrawal requests within 15-30 minutes."
                },
                {
                    question: 'How long does it take for funds to reach my bank account?',
                    answer: "We process all withdrawal requests within one business day. The time it takes for money to reach your bank account depends on your bank's policy. Bank withdrawals can take 30-45 minutes to appear in the customer's account."
                },
                {
                    question: 'In which Cryptos can I deposit or withdraw funds?',
                    answer: "You may deposit or withdraw in BTC, ETH, or USDT cryptocurrencies to and from your Monstrade Wallet."
                },
                {
                    question: 'What is the minimum deposit?',
                    answer: "The minimum deposit for all account types is $100. However, to get a VIP account, you have to reach a balance of minimum $50,000."
                }
            ]
         },
        { 
            header: 'Trading Conditions',
            questions: [
                {
                    question: 'Are hedging and scalping allowed?',
                    answer: "Hedging is allowed. The required margin for hedging positions on Classic, Pro, and VIP accounts is 0. Scalping is also allowed. There are no time limitations for keeping the positions open."
                },
                {
                    question: 'Do you have a dealing desk?',
                    answer: "We don’t have a dealing desk because we are a NDD broker and all our procedures are automated. We have no conflict of interest with our clients as 100% of the orders are cleared with liquidity providers."
                },
                {
                    question: 'Do you offer negative balance protection/cover negative balance?',
                    answer: "Traders who use the maximum leverage available face the risk of a negative balance.For example: Let’s assume that you have 200 USD on your account and you open 1 lot on USDJPY on Friday evening, with 1:500 leverage and 200 USD margin.On Sunday night, the market opens 30 pips away from Friday’s closing price in a direction against you, so your position will immediately have a loss of 30 pips x 10 USD = 300 USD loss, while you have only 200 USD on your account.The position will be automatically closed and your account would have a negative balance of -100 USD. This situation is 100% impossible when a trader uses 1:1 leverage. The higher leverage a trader uses, the more risks they take. Please also note that a negative balance may occur due to a slippage during high volatility.MonsTrade grants negative balance protection to all clients. The company may chose not to grant negative balance protection if the negative balance has been incurred as a result of fraudulent purposes or market abuse.Moreover, our Risk department is constantly monitoring our clients’ risk-taking and if we see that a client trades irresponsibly, then we will notify the client via e-mail and ask them to reduce risk exposure. Also, we might reduce the leverage on the client’s account. This is an illustrative example."
                },
                {
                    question: 'Does slippage occur in your platform?',
                    answer: "At Monstrade, slippage can occur during big news announcements, depending on the market conditions and volatility. There could be both positive and negative slippage."
                },
                {
                    question: 'How many platforms are available for trading?',
                    answer: "We offer some of the most popular and easy-to-use platforms the MetaTrader 5, which are available for Windows, OS X, iOS and Android. At Monstrade, you can also trade instantly with our Web Trader that requires no installation whatsoever."
                },
                {
                    question: 'What are the advantages of trading with Monstrade?',
                    answer: "Monstrade provides you with low spreads starting from 0.0 pips and ultra-fast execution speed of 0.15 second on average. Skrill, Neteller, FasaPay, UnionPay and credit card deposits are processed instantly while withdrawals are processed within one working day. We are proud that we have no restrictions on trading and no requotes. We also allow scalping, hedging, arbitrage, EAs and algorithms."
                },
                {
                    question: 'What are the minimum and maximum account leverage you offer?',
                    answer: "The minimum leverage is 1:1. The maximum and default leverage is 1:500.Metals: The leverage for gold is equal to the account leverage, while the leverage for silver is 4 times lower than the account leverage. If the trading account has a leverage of 1:500, then the leverage for gold will be 1:500 and the leverage for silver will be 1:125."
                },
                {
                    question: 'What are the minimum and maximum position sizes?',
                    answer: "The minimum trade size is 0.01 lot and the maximum trade size is 100 lots. The maximum amount of orders you can open on a MetaTrader account is 200."
                },
                {
                    question: 'What are your margin call/stop out levels?',
                    answer: "Our margin call / stop out levels are 100% / 30%Your account may be subject to a margin call if your account equity falls to a level that is equal to the margin of your existing positions. For example, you have an open position of 1 lot on EURUSD. The margin to hold that position is 200.When you opened the account, you had a 400 EUR equity on your account. When the position starts to move against you and your account equity falls to 200 EUR, you will have a margin call. But your position will not be closed yet. When your account equity falls to 30% of the required margin, then the system starts to close your positions immediately.Taking the above example, if you open a position with 200 EUR of margin and your account equity falls to 60 EUR, then the system starts to close your position. If you have several positions opened, then the system closes them starting from the one with the biggest loss.If, while closing the positions, your account equity reaches a level of more than 50% of the required margin, all other positions will remain open."
                },
                {
                    question: 'What is your order execution speed?',
                    answer: "Our average execution speed is around 0.20 seconds."
                },
                {
                    question: 'What is the trade execution model for Monstrade?',
                    answer: "Monstrade’s execution model is designed to provide a fast and secure trading experience for clients. Monstrade’s hybrid execution model therefore involves both market-making and straight through processing. Such a model guarantees an average execution time of 0.20 seconds of trades with no-requotes, and some of the best spreads in the market."
                }
            ] },
    ]
    return (
        <div>
            <div className=' pb-36 bg-[#F5F5F5]'>
                <div className='container-max mx-auto'>
                    <div className='pt-12 pb-8 text-center'>
                        <h1 className='text-4xl' >FAQs – MonsTrade</h1>
                    </div>
                </div>
                <div className='container-max mx-auto py-5'>
                    <div className='w-full rounded md:h-[120vh] h-[160vh] bg-white py-10 px-5'>
                        <div className='' >
                            <input className='w-full rounded-md border-2 text-sm p-1'
                                placeholder='Got Questions? We’ve got answers...' />
                        </div>
                        <div className='py-10' >
                            <div className="md:flex items-start">
                                <div className='md:py-0 py-6'>
                                    <ul className="nav nav-tabs flex flex-col flex-wrap list-none border-b-0 border-t-2 pl-0 mr-4 shadow-xl rounded-lg"
                                        id="tabs-tabVertical"
                                        role="tablist">
                                        {content.map((item, index) => {
                                            return <FAQCollapseButton index={index} header={item.header} />
                                        })}
                                    </ul>
                                </div>
                                <div className="tab-content h-[40vw] relative w-full" id="tabs-tabContentVertical">
                                    {content.map((item, index) => {
                                        return (
                                            <div className={`tab-pane ${index === 0 ? 'show active' : ''}`}
                                                id={`tabs-${index}`}
                                                role="tabpanel"
                                                aria-labelledby="tabs-home-tabVertical">
                                                <div className="tab-pane show active" id="tabs-homeVertical" role="tabpanel"
                                                    aria-labelledby="tabs-home-tabVertical">

                                                    <div className="accordion" id="accordionExample">
                                                        { item.questions.map( (question, sindex) => {
                                                            return <FAQInnerCollapse 
                                                                        question={question.question} 
                                                                        answer={question.answer} 
                                                                        index={sindex}/>
                                                        }) }

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
