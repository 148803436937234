import React from 'react'
//images
import icon1 from '../assets/icons/partner-receive-icon-1.svg'
import icon2 from '../assets/icons/partner-receive-icon-2.svg'
import icon3 from '../assets/icons/partner-receive-icon-3.svg'

export const MonstradePartneredReceive = () => {
    return (
        <div className='' >
            <div className='container-max mx-auto py-10'>
                <div className='text-center mb-16' >
                    <h3 className='text-2xl' >How to become a Monstrade partnerand receive affiliate commission?</h3>
                </div>
                <div className='grid md:grid-cols-3 grid-rows-1 gap-10 text-center mb-16' >
                    <div className='grid grid-cols-3' >
                        <div className='border-r-4 flex' >
                            <p className='text-4xl font-bold m-auto'>1</p>
                        </div>
                        <div className='col-span-2 px-6' >
                            <div className='flex justify-center mb-4' >
                                <img src={icon1} alt="" />
                            </div>
                            <p>Open an affiliate account </p>
                        </div>
                    </div>
                    <div className='grid grid-cols-3' >
                        <div className='border-r-4 flex' >
                            <p className='text-4xl font-bold m-auto'>2</p>
                        </div>
                        <div className='col-span-2 px-6' >
                            <div className='flex justify-center mb-4' >
                                <img src={icon2} alt="" />
                            </div>
                            <p>Get access to all marketing materials.</p>
                        </div>
                    </div>
                    <div className='grid grid-cols-3' >
                        <div className='border-r-4 flex' >
                            <p className='text-4xl font-bold m-auto'>3</p>
                        </div>
                        <div className='col-span-2 px-6' >
                            <div className='flex justify-center mb-4' >
                                <img src={icon3} alt="" />
                            </div>
                            <p>Get access to all marketing materials. </p>
                        </div>
                    </div>
                </div>
                <div className='flex'>
                    <div className='m-auto' >
                        <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='bg-mons-blue-1 text-white p-3 hover:text-white hover:bg-monstrade-green rounded-md font-semibold' >Become a Partner</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
