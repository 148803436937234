import React from 'react'

export const AffiliateAccounts = () => {
  return (
    <div className="bg-mons-gray-1">
      <div className="container-max mx-auto py-10">
        <div className="text-center mb-5">
          <h3 className="text-[40px] leading-[46px] mb-5 font-light">Increase your payouts with Affiliate accounts</h3>
          <p className="text-lg font-light">
            Your clients have an opportunity to trade on special <span className='underline'>Affiliate</span> accounts with increased mark-up and you will get profit by 2.5 times more in comparison with standard accounts. Below are the trading conditions for different types of affiliate accounts:
          </p>
        </div>
        <div className="grid md:grid-cols-3 grid-rows-1 gap-5 w-3/4 mx-auto">
          {/* Each card has fixed width and height */}
          <div className="flex-1">
            <div className="bg-white rounded overflow-hidden h-full">
              <div className="text-center bg-[#272727] text-white p-3 rounded text-sm">
                <p className="font-bold">Cent Affiliate</p>
              </div>
              <table className="table-fixed text-xs text-center w-full">
                <tbody>
                  <tr className="border-b-2">
                    <td className="border-r-2 py-4 w-1/2">Execution Mode</td>
                    <td className="w-1/2">Market Execution</td>
                  </tr>
                  <tr className="border-b-2">
                    <td className="border-r-2 py-4">Spread</td>
                    <td>from 1.8 pips</td>
                  </tr>
                  <tr className="border-b-2">
                    <td className="border-r-2 py-4">Affiliate commission rate:</td>
                    <td>20%</td>
                  </tr>
                  <tr className="border-b-2">
                    <td className="border-r-2 py-4">Commission for trading volume of 1 million USD</td>
                    <td>No commission</td>
                  </tr>
                  <tr className="border-b-2">
                    <td className="border-r-2 py-4">Available Instruments</td>
                    <td>28 currency pairs, Metals</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          
          <div className="flex-1">
            <div className="bg-white rounded overflow-hidden h-full">
              <div className="text-center bg-[#272727] text-white p-3 rounded text-sm">
                <p className="font-bold">Prime Affiliate</p>
              </div>
              <table className="table-fixed text-xs text-center w-full">
                <tbody>
                  <tr className="border-b-2">
                    <td className="border-r-2 py-4 w-1/2">Execution Mode</td>
                    <td className="w-1/2">Market Execution</td>
                  </tr>
                  <tr className="border-b-2">
                    <td className="border-r-2 py-4">Spread</td>
                    <td>from 1.2 pips</td>
                  </tr>
                  <tr className="border-b-2">
                    <td className="border-r-2 py-4">Affiliate commission rate:</td>
                    <td>50%</td>
                  </tr>
                  <tr className="border-b-2">
                    <td className="border-r-2 py-4">Commission for trading volume of 1 million USD</td>
                    <td>No commission</td>
                  </tr>
                  <tr className="border-b-2">
                    <td className="border-r-2 py-4">Available Instruments</td>
                    <td>28 currency pairs, Metals, Energies, Indices</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          
          <div className="flex-1">
            <div className="bg-white rounded overflow-hidden h-full">
              <div className="text-center bg-[#272727] text-white p-3 rounded text-sm">
                <p className="font-bold">ECN Affiliate</p>
              </div>
              <table className="table-fixed text-xs text-center w-full">
                <tbody>
                  <tr className="border-b-2">
                    <td className="border-r-2 py-4 w-1/2">Execution Mode</td>
                    <td className="w-1/2">Market Execution</td>
                  </tr>
                  <tr className="border-b-2">
                    <td className="border-r-2 py-4">Spread</td>
                    <td>from 0.8 pips</td>
                  </tr>
                  <tr className="border-b-2">
                    <td className="border-r-2 py-4">Affiliate commission rate:</td>
                    <td>50%</td>
                  </tr>
                  <tr className="border-b-2">
                    <td className="border-r-2 py-4">Commission for trading volume of 1 million USD</td>
                    <td>No commission</td>
                  </tr>
                  <tr className="border-b-2">
                    <td className="border-r-2 py-4">Available Instruments</td>
                    <td>All Instruments</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AffiliateAccounts