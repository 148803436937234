import React from 'react'

const CampaignBanner = ({ header, header2, desc, Banner }) => {
    return (
        <div className='bg-monstrade-blue'>
            <div className='flex flex-col lg:flex-row items-center'>
                <div className='text-white p-6 lg:pl-16 flex flex-col gap-10 flex-1  max-w-[460px] mx-auto text-left'>
                    <p className='text-xl font-semibold'>{header}</p>
                    <p className='text-6xl font-semibold '>{header2}</p>
                    <p className='text-xl '>{desc}</p>
                </div>
                <div className='w-full lg:w-auto relative'>
                    <div className='w-full h-full lg:block hidden' style={{
                        clipPath: 'polygon(15% 0, 100% 0, 100% 100%, 0 100%)',
                    }}>
                        <img src={Banner} alt="" className='w-full h-full object-cover' />
                    </div>
                    <div className='w-full h-full lg:hidden block'>
                        <img src={Banner} alt="" className='w-full h-full object-cover' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignBanner