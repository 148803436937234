import React from "react";
import TopBanner from "../components/TopBanner";
import Button from "../components/Button"
import Table from "../components/Table"
import TextArea from "../components/TextArea";
import CryptoContent from "../components/CryptoContent";
import Crypto3Content from "../components/Crypto3Content";
import AccountsForInvests from "../components/AccountsForInvests";
import Security from "../components/Security";
import CryptoImg from "../assets/images/crypto_img.png"
import CryptoIco from "../assets/icons/cryptocurrency.svg"
import Service247Ico from "../assets/icons/247service.svg"
import WalletIco from "../assets/icons/wallet2.svg"
import { SimpleCardListContainer } from "../components/ContainerLayout/SimpleCardListContainer";
import { BannerContainerV2 } from "../components/Containers/BannerContainerV2";

const mainTopic = "Cryptocurrencies"
const subText = "Trade at Monstrade on the best conditions similar to cryptoexchanges!"

const topic1 = "Invest in 10+ crypto instruments"
const topic2 = "Trade at any time convenient for you"
const topic3 = "Start trading with 100 USD"

const text1 = "Reliable access to trading CFDs on Bitcoin, Binance Coin, Solana, Stellar, Ethereum, Ripple and crypto-associated ETFs."
const text2 = "Monstrade clients have an opportunity to invest in cryptocurrencies 24/7."
const text3 = "To start investing, the minimum deposit of 100 USD "

const textAreaTitle = "What is Cryptocurrency?"
const textAreaContent = "Cryptocurrencies are digital (virtual) blockchain-based currencies. Security of transactions performed at Monstrade is provided by the application of encryption methods."

const landingOptions = {
    image: CryptoImg,
    imageGradient: 'linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5))',
    mainZindex: 'z-[-1]',
    header: {
        text: 'Cryptocurrencies',
        size: 'text-[40px] leading-[46px]',
    },
    subHeader: {
        text: "Trade at Monstrade on the best conditions similar to cryptoexchanges!",
        align: "text-center",
        size: "text-3xl	",
    }
}
const cards = [
    { header: 'Invest in 10+ crypto instruments', description: '', icon: CryptoIco, link: '' },
    { header: 'Trade at any time convenient for you', description: '', icon: Service247Ico, link: '' },
    { header: 'Start trading with 100 USD', description: '', icon: WalletIco, link: '' },
]

const Crypto = () => {
    return (
        <>
            {/* <TopBanner firstIcon={CryptoIco} secondIcon={Service247Ico} thirdIcon={WalletIco} mainTopic={mainTopic} subText={subText} topic1={topic1} topic2={topic2} topic3={topic3} text1={text1} text2={text2} text3={text3} bgimage={CryptoImg} is4Columns={false} />  */}
            <BannerContainerV2 options={landingOptions}>
                <div className="" >
                    <SimpleCardListContainer cards={cards} textClass={'text-white'} containerClass={'grid-cols-3'} />
                </div>
            </BannerContainerV2>
            <div className="absolute left-1/2 -translate-x-1/2 -mt-3">
                <Button
                    content="TRADE IN CRYPTOMARKET"
                    color="bg-monstrade-button-green"
                    py="py-3"
                    px="lg:px-6 px-2"
                    isArrow={true}
                />
            </div>
            <Table type={'crypto'} />
            <TextArea textAreaTitle={textAreaTitle} textAreaContent={textAreaContent} />
            <CryptoContent />
            <Crypto3Content />
            <AccountsForInvests />
            <Security />
        </>
    )
}

export default Crypto;