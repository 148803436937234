import React from 'react'

export const DocumentationCard = ({header, text, link}) => {
    return (
        <div className='bg-white lg:p-16 p-4 border-[1px] border-gray-400 rounded'>
            <p className='text-mons-blue-1 font-bold text-[26px] leading-[30px] mb-3'>{header}</p>
            <p className='mb-24 text-lg' >{text}</p>
            <a target='_blank' href={link} className='text-mons-blue-1 underline text-kg' >Open in new tab</a>
        </div>
    )
}
