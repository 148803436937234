import React from 'react'

export const PartnerReceive = () => {
  return (
    <div className=' bg-mons-gray-1' >
        <div className='container-max mx-auto py-16'>
            <div className='text-center mb-20' >
                <h2 className='text-3xl mb-5' >How much money do MonsTrade partners receive?</h2>
                <p className='text-sm' >
                    When calculating Affiliate commission, we take into 
                    account all transactions performed by your clients – no time or bonus limitations!
                </p>
            </div>
            <div className='grid md:grid-cols-4 md:gap-20 gap-10 md:mt-0 mt-[-30px]'>
                <div className='flex md:border-r-2 border-gray-400 md:row-span-3' >
                    <p className='m-auto font-semibold' >Account Types</p>
                </div>
                <div>
                    <p className='mb-10 font-bold' >Prime ECN</p>
                    <div className='bg-white border-2 drop-shadow-xl rounded overflow-hidden'>
                        <div className='bg-mons-blue-1 w-1/2' >
                            <p className='text-white font-bold px-1' >50%</p>
                        </div>
                    </div>
                </div>
                <div>
                    <p className='mb-10 font-bold' >Classic</p>
                    <div className='bg-white border-2 drop-shadow-xl rounded overflow-hidden'>
                        <div className='bg-mons-blue-1 w-2/5' >
                            <p className='text-white font-bold px-1'>40%</p>
                        </div>
                    </div>
                </div>
                <div>
                    <p className='mb-10 font-bold' >Cent</p>
                    <div className='bg-white border-2 drop-shadow-xl rounded overflow-hidden'>
                        <div className='bg-mons-blue-1 w-1/5' >
                            <p className='text-white font-bold px-1'>20%</p>
                        </div>
                    </div>
                </div>
                <div className='md:col-span-3 md:text-left text-center'>
                    <p>Affiliate commission rate is specified in the percentage of the Company’s revenue.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
