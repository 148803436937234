import React from "react";
import monstradeLogo from '../assets/images/monstrade-logo.png'
import menuContent from '../features/content/menu.content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import xMasLogo from '../assets/images/x-mas-logo.svg'
import FXIcon from '../assets/icons/wikifx.png'
import Verified from '../assets/icons/verified-icon.png'

const navigation = menuContent()

const socialMedias = [
    { logo: "instagram", link: "https://www.instagram.com/monstradefx/" },
    { logo: "youtube", link: "https://www.youtube.com/@monstradefx" },
    { logo: "x-twitter", link: "https://twitter.com/MonsTradeFX" },
]

const Footer = () => {
    return (
        <>
            <div className="container-full">
                {/* <div><hr class=" h-px bg-gray-200 border-0 dark:bg-gray-700"></hr></div> */}
                <div className="container-max mx-auto">

                    <div className="text-center py-10">
                        <a target="_blank" href="https://www.wikifx.com/tr/dealer/3545945396.html">
                            <div className="inline-block">
                                <img className="max-w-[200px] mx-auto lg:inline-block pb-3" src={FXIcon} alt="" />
                                <img className="max-w-[50px] inline-block sm:pb-3 mx-4" src={Verified} alt="" />
                                <p className="sm:text-2xl inline-block">TRUSTED BROKER <span className="font-bold">by WikiFX</span></p>
                            </div>
                        </a>
                    </div>
                    
                    <footer className=" text-left  text-gray-600">
                        <div className="md:mx-6 py-6 lgtext-center md:text-left">
                            <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-8 md:gap-1 gap-4">
                                <div className="lg:border-r-2 pr-2 md:col-span-2 col-span-3">
                                    <h6 className="uppercase font-semibold flex 
                                        items-center justify-center md:justify-start">
                                        <div>
                                            <img src={monstradeLogo} alt="monstrade-logo" />
                                            {/* <img src={xMasLogo} alt="" /> */}
                                        </div>
                                    </h6>
                                    <p className="lg:mb-10 mb-2 text-center md:text-left md:text-md text-sm" >support@monstrade.com<br/></p>
                                    <p className="lg:mb-10 mb-2 text-center md:text-left md:text-md text-sm" >
                                        Antifašističke borbe 29-E, Belgrad 11070, Serbia
                                    </p>
                                    <div className="flex items-center gap-3 mb-3">
                                        {socialMedias.map((item, index) => {
                                            return (
                                                <div key={index} className="bg-monstrade-blue md:m-0 mx-auto rounded-full w-[38px] h-[38px] flex" >
                                                    <a href={item.link} className="m-auto" >
                                                        <FontAwesomeIcon icon={["fab", item.logo]} size="lg" className="text-white" />
                                                    </a>
                                                </div>
                                                
                                            )
                                        })}
                                    </div>
                                    
                                </div>
                                {navigation.map((item, index) => {
                                    if (item.status === false) return <></>
                                    return (
                                        <div className="" key={index}>
                                            <h6 className="uppercase font-semibold mb-3 flex justify-center md:justify-start">
                                                {item.header}
                                            </h6>
                                            {item.subItems.map((subItem, sindex) => {
                                                if (subItem.status === false) return <></>
                                                return (
                                                    <div className={subItem.name == "TRADING INSTRUMENTS" || 
                                                                    subItem.name == "TRADING" ||
                                                                    subItem.name == "COMPUTER" ? `sm:h-auto h-[140px]` : 'sm:h-auto h-[100px]'} key={sindex} >
                                                        <p className="mt-3 mb-1 text-gray-800 font-semibold text-sm">
                                                            {subItem.name.charAt(0).toUpperCase() + subItem.name.slice(1).toLowerCase()}

                                                        </p>
                                                        {subItem.subItems.map((subItem2, sindex2) => {
                                                            if (subItem2.status === false) return <></>
                                                            return (
                                                                <a key={sindex2} href={subItem2.link} className="text-gray-600 block text-xs"> {subItem2.name} </a>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="text-center md:p-6">
                            <div className="flex text-xs" >
                                <div className="m-auto mb-3">
                                    <div>
                                        <p>NFA (National Futures Association) is the oversight agency for the US derivatives industry. Brokerages or other financial institutions that offer futures trading services are regulated and licensed by the NFA.<a className="font-bold" href="/about/company/regulations">Our Company's Registration on NFA: 0560385</a></p><br/>
                                        <p>Money Services Businesses (MSBs)  pursuant to the Bank Secrecy Act (BSA) regulations at 31 CFR 1022.380(a)-(f), administered by the Financial Crimes Enforcement Network (FinCEN).  Information contained on this site has been provided by the MSB registrant.  <a className="font-bold" href="/about/company/regulations">MSB Registration Number: 31000252702749</a></p>
                                    </div>
                                    <p className="text-xl font-bold py-3">Please Read The Risk Warning!</p>
                                    <p className="text-xs pb-4">
                                        CFDs and Fx instruments are complex instruments and come with a high
                                        risk of losing money rapidly due to leverage. 77.20% of retail investor
                                        accounts lose money when trading CFDs and Fx instrument with this provider.
                                        You should consider whether you understand how CFDs and Fx instrument work
                                        and whether you can afford to take the high risk of losing your money.
                                    </p>
                                    <p className="text-xs pb-4">
                                        "Monstrade Dooel Skopje" does not issue advice, recommendations or opinions in relation to acquiring, holding or disposing of a CFD. "Monstrade Dooel Skopje" is not a financial advisor and all services are provided on an execution-only basis. This communication is not an offer or solicitation to enter into a transaction and shall not be construed as such.
                                        This website is not directed at any jurisdiction and is not intended for any use that would be contrary to local law or regulation. Services are not available for residents of USA, Canada, Iran, and North Korea
                                        By using "monstrade.com" you agree to use our cookies to enhance your experience.
                                        "Monstrade Dooel Skopje", all right reserved 2021.
                                    </p>
                                    <a href="/privacy-policy" className="font-semibold md:px-3 px-1 border-r-[1px]" >Privacy Policy</a>
                                    <a href="/aml-policy" className="font-semibold md:px-3 px-1 border-r-[1px]" >AML Policy</a>
                                    <a href="/terms-and-conditions" className="font-semibold md:px-3 px-1 border-r-[1px]" >Terms&Conditions</a>
                                    <a href="/cookie-policy" className="font-semibold md:px-3 px-1" >Risk Warning</a>
                                </div>
                            </div>
                            <p className="text-sm text-gray-400 " href="https://monstrade.com/">©2021 This website is owned and operated by MONSTRADE Global Limited.</p>
                            <p className="text-sm text-gray-400 ">Our Company's Registration on NFA: 0560385</p>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    )
}

export default Footer;