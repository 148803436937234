import React, { useState, useEffect } from "react";
import Calendar from 'react-calendar';
import styled from 'styled-components';
import Moment from 'moment';
import jsPDF from "jspdf";
import "jspdf-autotable";
import Select from "react-select";
import EconomicCalenderComponent from "../components/Calendars/EconomicCalendar.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EconomicEvents from "../components/Tables/EconomicEvents";
import Skeleton from "react-loading-skeleton";
import ReactCountryFlag from "react-country-flag"
import { DownloadConfigForm } from "../components/Forms/DownloadConfigForm";
import { CountryFlagLabels } from "../components/Labels/CountryFlagLabels";

var today = new Date();

const EconomicCalenderContainer = () => {
    const [apiData, setApiData] = useState();
    const [displayedData, setDisplayedData] = useState([]);
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [isCalenderOpen, setisCalenderOpen] = useState(false);
    const [isCalenderFilterOpen, setIsCalenderFilterOpen] = useState(false);
    const [isConfigOpen, setIsConfigOpen] = useState(false);
    const [rangeOfDate, setStartDate] = useState([today, new Date(new Date().setDate(today.getDate() + 7))]);
    const dataHeaders = ["date", "country", 'event', 'change', 'actual', 'estimate', 'previous'];
    const [countryOptions, setCountryOptions] = useState([]);
    //api call func
    const fetchNews = async (dates) => {
        try {
            setIsApiLoading(true);
            console.log(dates)
            let start = Moment(dates[0]).format('YYYY-MM-DD')
            let end = Moment(dates[1]).format('YYYY-MM-DD')
            const response = await fetch(`/finage/fnd/economic-calendar?from=${start}&to=${end}&apikey=API_KEY${process.env.REACT_APP_FINAGE_API_KEY}`,
                {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Origin": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Content-Type"
                    }
                })
            let data = await response.json()
            setApiData(data);
            setDisplayedData(data);
            //api uniq value of contry
            let countries = []
            data.forEach((d) => {
                if(countries.includes(d.country)){ return }
                countries.push(d.country)
            })
            countries = countries.map( country => {return {'value' : country, 'label' : <CountryFlagLabels country={country} text={country}/> }})
            setCountryOptions(countries)
            //set api on load status
            setIsApiLoading(false);
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
    }, [apiData])

    useEffect(() => {
        setApiData([])
        setDisplayedData([])
        fetchNews(rangeOfDate)
        setisCalenderOpen(false)
    }, [rangeOfDate])

    const exportPDF = (events, keys, pageSize) => {
        const dataFilter = keys ? keys : dataHeaders
        const unit = "pt";
        const size = pageSize ? pageSize : "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Monstrade Economic Calender";
        const headers = [dataFilter];

        const data = events.map(event => {
            return dataFilter.map((filter) => event[filter])
        });

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 23);
        doc.autoTable(content);
        doc.save("monstrade-economic-calender.pdf")
    }
    const downloadHandler = () => {

        if (apiData.length > 0) {
            exportPDF(displayedData);
        }
    }

    const countryFilterHandler = (e) => {
        if(e.length == 0){ setDisplayedData(apiData) }
        if(e.length > 0){
            let filter = e.map( selected => selected.value )
            console.log(filter)
            let data = apiData.filter( (data) => { return filter.includes(data.country) ? 1 : 0})
            setDisplayedData(data);
        }
    }
    return (
        <>
            <div className="">
                <div className="container-max mx-auto">
                    <div className="grid md:grid-cols-3 sm:grid-cols-2 pt-10">
                        <div className="w-full flex lg:col-span-2">
                            <div className="flex justify-center">
                                <div>
                                    <p className="md:space-x-1 space-y-1 md:space-y-0 mb-4">
                                        <button className="inline-block px-6 py-2.5 
                                                        text-black font-medium text-xs leading-tight uppercase 
                                                        rounded-sm border-2  hover:shadow-lg focus:bg-[#f2f2f4]  focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                                            type="button"
                                            onClick={() => { setisCalenderOpen(isCalenderOpen ? false : true) }}
                                        >
                                            <span className="mx-1">
                                                <FontAwesomeIcon icon="fa-regular fa-calendar" />
                                            </span>
                                            <span className="px-3" >
                                                {rangeOfDate[0] && rangeOfDate[1] ? `${Moment(rangeOfDate[0]).format('DD.MM.YYYY')} - ${Moment(rangeOfDate[1]).format('DD.MM.YYYY')}` : 'not'}
                                            </span>
                                            <span className="mx-1">
                                                <FontAwesomeIcon icon="fa-solid fa-caret-down" />
                                            </span>
                                        </button>
                                    </p>
                                    <div className={`collapse absolute z-10 ${isCalenderOpen ? 'show' : ''}`} id="collapseExample">
                                        <div className="block rounded-lg shadow-lg bg-white">
                                            <CalendarContainer>
                                                <Calendar onChange={setStartDate} value={rangeOfDate} selectRange={true} className='' />
                                            </CalendarContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex col-span-1 md:justify-end justify-start" >
                            <div className="flex" >
                                <div className="px-1">
                                    <button className="my-auto w-[30px] h-[30px]" >
                                        <FontAwesomeIcon icon="fa-solid fa-circle-info" size="lg" />
                                    </button>
                                </div>
                                <div className="px-1">
                                    <button onClick={downloadHandler} className="w-[30px] h-[30px] bg-slate-300 p-1" >
                                        <FontAwesomeIcon icon="fa-solid fa-download" size="lg" />
                                    </button>
                                </div>
                                <div className="px-1 relative" >
                                    <button onClick={() => { setIsConfigOpen(isConfigOpen ? false : true) }} className="w-[30px] h-[30px] bg-slate-300 p-1" >
                                        <FontAwesomeIcon icon="fa-solid fa-gear" size="lg" />
                                    </button>
                                    <div className={`collapse absolute z-10 right-0 w-[255px] ${isConfigOpen ? 'show' : ''}`} id="collapseExample">
                                        <div className="block rounded-lg shadow-lg bg-white">
                                            <DownloadConfigForm dataHeaders={dataHeaders} exportPDF={exportPDF} apiData={displayedData} />
                                        </div>
                                    </div>
                                </div>
                                <div className="px-1 relative" >
                                    <button onClick={() => { setIsCalenderFilterOpen(isCalenderFilterOpen ? false : true) }} className="bg-[#304c70] h-[30px] font-semibold text-xl text-white px-2" >Filter</button>
                                    <div className={`collapse absolute z-10 right-0 w-[255px] ${isCalenderFilterOpen ? 'show' : ''}`} id="collapseExample">
                                        <div className="block rounded-lg shadow-lg bg-white">
                                            <div className="p-3" >
                                                <label className="px-1 text-gray-400 text-sm" >Country</label>
                                                <Select
                                                    defaultValue={[]}
                                                    options={countryOptions}
                                                    isSearchable={true}
                                                    isMulti
                                                    onChange={countryFilterHandler}
                                                    isLoading={isApiLoading} />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EconomicEvents events={displayedData} isApiLoading={isApiLoading} />
        </>
    )
}

const CalendarContainer = styled.div`
    .react-calendar__tile--range{
        background : #1352FF;
    }
    .react-calendar{
        border: 1px solid #f2f2f4;
        border-radius: 5px;
    }
`;

export default EconomicCalenderContainer;