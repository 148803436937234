import React from 'react'
import Banner from '../../assets/images/banners/monscash-banner.png'
import CampaignBanner from '../../components/Banners/CampaignBanner'

const MonsCash = () => {
  return (
    <>
      
      <CampaignBanner
        header='| MONSTRADE PRIVILEGES'
        header2='MonsCash'
        desc='Forex trading involves high risks and may result in the loss of your entire investment. Avoid trading without adequate knowledge.'
        Banner={Banner}
      />
      <div className='bg-[#F3F5F6] p-16 text-[#787878]'>
        <div className='max-w-[1440px] mx-auto bg-white p-16'>
          <p className='text-3xl font-bold pb-4'>Campaign Details</p>
          <p>
            It is a type of collateral support incentive available within the MonsTrade framework. You can earn MonsCash through various promotions with MonsTrade.
            <br /><br />
            There is no specific usage period for MonsCash.
            <br /><br />
            MonsCash allows you to increase your collateral, providing the opportunity to take positions with higher lot sizes.
            <br /><br />
            The percentage rates of MonsCash rewards may vary depending on the positions.
            <br /><br />
            MonsCash rewards can be withdrawn.
            <br /><br />
            Regarding this privilege, MonsTrade reserves the right to make restrictions and changes in risk management.
          </p>
        </div>
      </div>
    </>
  )
}

export default MonsCash